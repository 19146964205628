import React, { useEffect, useState,useContext } from "react";
import { Link } from "react-router-dom";
import { GlobalStateContext } from "../pages/GlobalStateContext"
import { toast } from "react-toastify";
import { Navigate } from "react-router-dom";
import { FaEye, FaEyeSlash } from "react-icons/fa"; // Import eye icons

//const apiUrl = "HTTPS://siamapi.azurewebsites.net"

function LoginForm() {
   
  const {apiHostUrl, setApiHostUrl} = useContext(GlobalStateContext);
  const {apiKey, setApiKey} = useContext(GlobalStateContext);
  const { userGuid, setUserGuid } = useContext(GlobalStateContext);
  const {isLoggedIn, setIsloggedIn} = useContext(GlobalStateContext);

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const [navAway, setNavAway] = useState(false);

  const [auth, setAuth] = useState(false);
  const [loading, setLoading] = useState(false);

  const myHeaders = new Headers();

  const [showPassword, setShowPassword] = useState(false);
  const [os, setOS] = useState('');  

  const [userInfo, setUserInfo] = useState({
    os: '',
    browser: '',
    deviceType: '',
    platform: '',
    language: '',
  });


  useEffect(() => {
    const userAgent = window.navigator.userAgent;
    const platform = window.navigator.platform;
    const language = window.navigator.language;

    // Detect OS
    let os = 'Unknown';
    if (userAgent.indexOf('Win') !== -1) {
      os = 'Windows';
    } else if (userAgent.indexOf('Mac') !== -1) {
      os = 'MacOS';
    } else if (userAgent.indexOf('Linux') !== -1) {
      os = 'Linux';
    } else if (userAgent.indexOf('Android') !== -1) {
      os = 'Android';
    } else if (userAgent.indexOf('iPhone') !== -1 || userAgent.indexOf('iPad') !== -1) {
      os = 'iOS';
    }

    // Detect Browser
    let browser = 'Unknown';
    if (userAgent.indexOf('Chrome') !== -1 && userAgent.indexOf('Safari') !== -1) {
      browser = 'Chrome';
    } else if (userAgent.indexOf('Firefox') !== -1) {
      browser = 'Firefox';
    } else if (userAgent.indexOf('Safari') !== -1) {
      browser = 'Safari';
    } else if (userAgent.indexOf('Edge') !== -1) {
      browser = 'Edge';
    } else if (userAgent.indexOf('MSIE') !== -1 || userAgent.indexOf('Trident') !== -1) {
      browser = 'Internet Explorer';
    }

    // Detect Device Type
    let deviceType = 'Desktop';
    if (/Mobi|Android/i.test(userAgent)) {
      deviceType = 'Mobile';
    } else if (/iPad|Tablet/i.test(userAgent)) {
      deviceType = 'Tablet';
    }

    // Set the state with gathered info
    setUserInfo({
      os,
      browser,
      deviceType,
      platform,
      language,
    });
  }, []);


  myHeaders.append("Accept", "text/plain");

  const requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
   
    headers: {
      "X-API-KEY": apiKey, // Replace with your actual API key
    },
  };

  useEffect(() => {
    
    //setting the username & password for next visit
    //setPassword(localStorage.getItem("password"))
    setUsername(localStorage.getItem("username"))

  }, []); // Empty dependency array means this effect runs once when the component mounts
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

 
  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);

    // Here you can perform authentication logic
    //console.log("Username:", username);
    //console.log("Password:", password);

    // Reset form fields
    setUsername("");
    setPassword("");

    setUserGuid("hello");
    
    console.log(apiKey)


    let url =
    apiHostUrl + "/user/Login/" +
      username +
      "/" +
      password +
      "";

    fetch(url, requestOptions)
      .then((response) => response.text())
      .then((result) => {

        console.log(result)

        if (result.includes('Not') || result.includes('Unauthorized')) {

          toast.warn("We couldn't log you in with the details provided");

          setNavAway(true);

        } else {
          

          localStorage.setItem("username",username)
          //localStorage.setItem("password",password)
          toast.success("Login OK");

          setAuth(true);

          setIsloggedIn(true)
          
          sessionStorage.setItem("userId", result);
          sessionStorage.setItem("phoneNumber",username)
         
          setUserGuid(result);
      
          console.log(userInfo)

         
          const myHeaders = new Headers();
          myHeaders.append("Content-Type", "application/json");

          myHeaders.append("X-API-KEY", apiKey) // Replace with your actual API key
          
          
          const raw = JSON.stringify({
            "userId": result,
            "os": userInfo.os,
            "browser": userInfo.browser,
            "deviceType": userInfo.deviceType,
            "platform": userInfo.platform,
            "language": userInfo.language
          });
          
          const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow"

          };
          
          fetch(apiHostUrl + "/user/UserVisit", requestOptions)
            .then((response) => response.text())
            .then((result) => console.log(result))
            .catch((error) => console.error(error));

        }

        setLoading(false);
      })
      .catch((error) => {
        console.error(error);

        toast.warning(error);
      });
  };

  return (
    <>
      {navAway ? <Navigate to="/" /> : ""}

      {auth ? (
        <Navigate to="/mydetails" />
      ) : (
        <div className="container_2">
          {loading ? <img src="../Small_Spinner.svg" /> : ""}

          <form onSubmit={handleSubmit}>
            <div>
              <h3>John Dory's Loyalty</h3>
              {/* <label htmlFor="username">Username:</label> */}
              <input
                style={{ margin: "3px" }}
                className="custom-input"
                type="number"
                id="username"
                value={username}
                placeholder="Phone Number"
                onChange={(e) => setUsername(e.target.value)}
                required
              />
              
            </div>
            <div  style={{ position: "relative", display: "inline-block" }}>
              {/* <label htmlFor="password">Password:</label> */}
              <input
                style={{ margin: "3px", paddingRight: "30px" }}
                className="custom-input"
                type={showPassword ? "text" : "password"}
                id="password"
                value={password}
                placeholder="Password"
                onChange={(e) => setPassword(e.target.value)}
                required
                
              />
              <span
                  onClick={togglePasswordVisibility}
                  style={{
                    position: "absolute",
                    right: "10px",
                    top: "50%",
                    transform: "translateY(-50%)",
                    cursor: "pointer",
                  }}
                >
                  {showPassword ? <FaEyeSlash /> : <FaEye />}{" "}

                </span>
            </div>
            <button
              className="primary-button"
              style={{ margin: "3px" }}
              type="submit"
            >
              Login
            </button>

            <div>
              <Link to="/forgot">
                <button className="secondary-button" style={{ margin: "3px" }}>
                  Reset password
                </button>
              </Link>
            </div>

            <div></div>
          </form>
        </div>
      )}
    </>
  );
}

export default LoginForm;
