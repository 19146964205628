
//const apiUrl = "HTTPS://siamapi.kiwijones.com"
//const {defaultSubscription, setDefaultSubscription} = useContext(GlobalStateContext);


export const SendInternalEmail = (apiHost, email, subject, message,apiKey) => {
  
  const myHeaders = new Headers();

  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("X-API-KEY", apiKey)

  const raw_email = JSON.stringify({
    apiKey: "<string>",
    messageFrom: "<string>",
    welcomeMessage: "<string>",
    first: "<string>",
    from: "<string>",
    to: email,
    subject: subject,
    message: message,
  });

  const requestOptions_email = {
    method: "POST",
    headers: myHeaders,
    body: raw_email,
    redirect: "follow",
  };

  var url = apiHost + "/Communication/Email_Via_ETL"

  fetch(
    url, requestOptions_email
  )
    .then((response) => response.text())
    .then((result) => {
      console.log(" email sent");
      return "Internal Email Sent";
      
    })
    .catch((error) => {
      console.error(error);
      return error;
    });
};

export const SendWelcomeEmail = (apiHost,email, apiKey, subscriptionId) => {

    const requestOptions = {
    method: "GET",
    redirect: "follow",
    headers: {
      "X-API-KEY": apiKey, // Replace with your actual API key
    },
  };

  var url = apiHost + "/Restaurant/GetSubscription?subscriptionId=" + subscriptionId + ""

  fetch(
    url,
    requestOptions
  )
    .then((response) => response.text())
    .then((result) => {

     let data = JSON.parse(result);
 
      const myHeaders = new Headers();

      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("X-API-KEY", apiKey)
    
      const raw_email = JSON.stringify({
        apiKey: "<string>",
        messageFrom: "<string>",
        welcomeMessage: "<string>",
        first: "<string>",
        from: "<string>",
        to: email,
        subject: data.name,
    
        message: data.welcomeMessage,
      });
    
      const requestOptions_email = {
        method: "POST",
        headers: myHeaders,
        body: raw_email,
        redirect: "follow",
      };
      var url = apiHost + "/Communication/Email_Via_ETL"
      fetch(
        url,
        requestOptions_email
      )
        .then((response) => response.text())
        .then((result) => {
          //console.log("email sent");
          return "Customer Welcome Email Sent";
        })
        .catch((error) => {
          console.error(error);
          return error;
        });
      
    })

    .catch((error) => {
      console.error(error);
    });

  
};

