// Navbar.js
import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import "./navbar.css";
import MySVG from "../src/assets/Logos/JohnDory_Logo.png";

import { GlobalStateContext  } from "./pages/GlobalStateContext";


const Navbar = ({ isAuthenticated, onLogout }) => {
  const [isMenuOpen, setMenuOpen] = useState(false);
  
  const {isLoggedIn, setIsloggedIn} = useContext(GlobalStateContext)

  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen);
  };

  const closeMobileMenu = () => {
    setMenuOpen(false);
  };

  const redirectToMainPage = () => {
    
    closeMobileMenu();

    window.open(
      "https://www.johndorys.co.uk/",
      "_blank" // <- This is what makes it open in a new window.
    );
  };
  return (
    <nav className="navbar">
      <Link className="logo" to="/" onClick={redirectToMainPage}>
        <img src={MySVG} alt="Logo" className="responsive-logo" />
      </Link>
     
      <div className={`nav-items ${isMenuOpen ? "open" : ""}`}>
        
        <Link className="nav-link" to="/" onClick={closeMobileMenu}>
          Home
        </Link>
        <Link className="nav-link" to="/login" onClick={closeMobileMenu}>
          Check Points
        </Link>

        {isAuthenticated ? (
          <Link className="nav-link" to="/profile" onClick={closeMobileMenu}>
            Profile
          </Link>
        ) : (
          <>
            <Link className="nav-link" to="/register" onClick={closeMobileMenu}>
              Register
            </Link>
            <Link className="nav-link" to="/profile" onClick={closeMobileMenu}>
              Profile
            </Link>
          </>
        )}
      </div>

      <div className="hamburger" onClick={toggleMenu}>
        <i className={isMenuOpen ? "fas fa-times" : "fas fa-bars"} />
      </div>
    </nav>

  );
}

export default Navbar;