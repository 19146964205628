import React from "react";
import { useState, useEffect } from "react";
import Points from "./Points";
import Transactions from "./Transactions";
import Coupons from "./Coupons";
import Profile from "./Profile";
import PhoneBarcode from './././BarcodeComponent';

const MyDetails = () => {

  const [showProfile, setShowProfile] = useState(false);

  const phoneNumber = "1234567890"; // Replace with the actual phone number
  function onMyProfile() {
    setShowProfile(true);
  }

  function onLogout(){
    
  }

  return (
    <div className="container">

      <div className="content">
        <Points userId={sessionStorage.getItem("userId")} />

      </div>
      <div className="content">
        
        <PhoneBarcode phoneNumber={sessionStorage.getItem("phoneNumber")} />

      </div>
      <div className="content">
        
        <Transactions userId={sessionStorage.getItem("userId")} />    

      </div>

      <div className="content">
        
        <Coupons userId={sessionStorage.getItem("userId")} />    

      </div>
      
    </div>
  );
};

export default MyDetails;
