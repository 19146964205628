import { useState, useContext, useEffect } from "react";
import React from "react";
import Login from "./Login";
import { SendInternalEmail, SendWelcomeEmail } from "./Messages";
import { SetPasswordOnServer } from "./Utils";
import { GlobalStateContext } from "../pages/GlobalStateContext";
import { FaEye, FaEyeSlash } from "react-icons/fa"; // Import eye icons

//const apiUrl = "HTTPS://siamapi.azurewebsites.net"

const Register = () => {


  const [isAutofilled, setIsAutofilled] = useState(false);


  const { apiHostUrl, setApiHostUrl } = useContext(GlobalStateContext);
  const {apiKey, setApiKey} = useContext(GlobalStateContext);
  const {defaultSubscription, setDefaultSubscription} = useContext(GlobalStateContext);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [birthMonth, setBirthMonth] = useState("0");
  const [restaurant, setRestaurant] = useState("0");
  const [isValid, setIsValid] = useState(true);
  const [formValid, setFormValid] = useState(false);
  const [userExists, setUserExists] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [staff, setStaff] = useState("0");
  const [staffOptions, setStaffOptions] = useState([]); // State to store staff options
  const [selectedStaff, setSelectedStaff] = useState(""); // State to store the selected staff
  const [showPassword, setShowPassword] = useState(false);
  const [showConsent, setShowConsent] = useState(false);
  const [submitButton, setSubmitButton] = useState("---")


  const [validFirstName, setValidFirstName] = useState("white")
  const [validLastName, setValidLastName] = useState("white")
  const [validPhone, setValidPhone] = useState("white")
  const [validEmail, setValidEmail] = useState("white")


  // useEffect(() => {
  //   const form = document.querySelector('form');
  //   const handleAutofill = () => {
  //     // Check if any input has been autofilled
  //     const inputs = form.querySelectorAll('input');
  //     inputs.forEach(input => {
  //       if (input.matches(':-webkit-autofill')) {
  //         setIsAutofilled(true);
  //       }
  //     });
  //   };

  //   // Listen to form focus or change events
  //   form.addEventListener('input', handleAutofill);
  //   form.addEventListener('focus', handleAutofill);

  //   return () => {
  //     form.removeEventListener('input', handleAutofill);
  //     form.removeEventListener('focus', handleAutofill);
  //   };
  // }, []);
  

  // useEffect(() => {
  //   const requestOptions = {
  //     method: "GET",
  //     redirect: "follow",
  //   };
  //   const consent = localStorage.getItem('gdprConsent');

  //   if (!consent) {

  //     setShowConsent(true);  // Show consent banner if consent is not yet given
  //   }
  //   let url = apiHostUrl + "/Restaurant/GetAllStaff"

  //   //console.log(url)
  //   fetch(
  //     url,
  //     requestOptions
  //   )
  //     .then((response) => response.json())
  //     .then((result) => {

  // 	  //console.log(result)

  //       setStaffOptions(result); // Store the fetched data in state


  //     })
  //     .catch((error) => console.error(error));
  // }, []);  //  end of use effect
  

    // This effect will run whenever `userExists` changes
    useEffect(() => {

      //console.log("userExists has been updated:", userExists);

      checkFormValid()
      // Here you can also trigger any logic after userExists is updated
    }, [userExists]);

    useEffect(() => {

      //console.log("STAFF:", staffOptions);

      //checkFormValid()
      // Here you can also trigger any logic after userExists is updated
    }, [staffOptions]);

    useEffect(() => {
    }, [validFirstName]);

    useEffect(() => {
    }, [validLastName]);

    useEffect(() => {
    }, [validPhone]);

    useEffect(() => {
    }, [validEmail]);

  const handleAccept = () => {
    localStorage.setItem('gdprConsent', 'accepted');  // Store consent
    setShowConsent(false);  // Hide banner
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  // setStaffData(staffJson);

  const handleStaffChange = (event) => {
    setSelectedStaff(event.target.value);
  };

  const handleBirthMonthChange = (event) => {
    if (Number(event.target.value) > -1) {
      setBirthMonth(event.target.value);
      //console.log('true')
      //setFormIsvalid(true);
    } else {
      setBirthMonth(event.target.value);
      //console.log('false')
      //setFormIsvalid(false);
    }
  };

  const handleRestaurantChange = (event) => {
    if (event.target.value != "0") {

      setRestaurant(event.target.value);

      console.log(event.target.value);

      const requestOptions = {
        method: "GET",
        redirect: "follow",
        headers: {
          "X-API-KEY": apiKey, // Replace with your actual API key
        },
      };

      let url = apiHostUrl + "/Restaurant/GetStaffForRestaurant/" +
        event.target.value;

      console.log(url);
      fetch(url, requestOptions)
        .then((response) => response.json())

        .then((result) => {

         console.log(result)

        setStaffOptions(result); // Store the fetched data in state

        })
        
        .catch((error) => console.error(error));

      //console.log('true')
      //setFormIsvalid(true);
    } else {
      setRestaurant(event.target.value);
      //console.log('false')
      //setFormIsvalid(false);
    }
  };

  const handleFormSubmit = (event) => {

    console.log("Submit");
    
    if (lastName.length > 2) {
      if (firstName.length > 2) {
        if (email.length > 5) {
          if (phone.length > 8) {
            console.log('Posting data')
            	
            postUserData();

          }
        }
      }
    }
    setLoading(false);
  };

  async function postUserData() {
    setLoading(true);
    const myHeaders = new Headers();

    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("X-API-KEY", apiKey)

    const raw = JSON.stringify({
      restaurantId: restaurant,
      firstName: firstName,
      lastName: lastName,
      phoneNo: phone,
      email: email,
      birthMonth: Number(birthMonth),
      userRole: 0,
      cardNumber: "",
      staffId: selectedStaff
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    console.log(raw);

    let url = apiHostUrl + "/user/RestaurantUserAsync";


    console.log(url);

    let response = await fetch(url, requestOptions);

    // Check if the request was successful
    if (!response.ok) {
      throw new Error("Network response was not ok " + response.statusText);
    } else {

      console.log("--- OK  ---")  

      setFormSubmitted(true);

      setLoading(false);

      let dataPass = await response.json();

      console.log(dataPass);

      console.log(password);

      setPassword(dataPass.password);

      // this is to set the encrypted password on the server
      SetPasswordOnServer(dataPass.id, password, apiHostUrl,apiKey);

      // Log the data

      SendWelcomeEmail(apiHostUrl, email,apiKey,defaultSubscription);

      SendInternalEmail(apiHostUrl,"brian@kiwijones.com","Loyalty Signup " +  defaultSubscription, raw, apiKey);

    }
  }

  const handleChangeEmail = (event) => {
    const inputValue = event.target.value;
    setEmail(inputValue);
    setIsValid(validateEmail(inputValue));

    checkFormValid();
  };

  const validateEmail = (email) => {
    // Regular expression for email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const checkFormValid = (event) => {

    // console.log("checkFormValid");
    // console.log(formValid)

    setFormValid(false);
    setSubmitButton("---")
    
    //console.log("user exists: " + userExists)

    const phoneRegex = /^\+?[0-9]{1,3}?[-.\s]?\(?[0-9]{1,4}\)?[-.\s]?[0-9]{3,4}[-.\s]?[0-9]{3,4}$/;

    setValidFirstName("red")
    setValidLastName("red")
    setValidEmail("red")
    setValidPhone("red")
    if (firstName.length > 2) {
      setValidFirstName("green")
      if (lastName.length > 2) {
        setValidLastName("green")
          if (email.length > 5) {
            setValidEmail("green")
            if (phone.length > 8) {
              setValidPhone("green")
                if(phoneRegex.test(phone))
                {
                  //console.log('Phone ex pass')
                  if(userExists  == false) 
                    {
                      //console.log("Setting submit")
                      setSubmitButton("Submit")
                      setFormValid(true);
                    }
                }
                else{
                  console.log('Phone ex fail')
                  setValidPhone("red")
                }  
            }
          }
      }
    }
    

  };

  const Password = (event) => {
    setPassword(event);
    checkFormValid();
  };

  const FirstName = (event) => {
    setFirstName(event);
    checkFormValid();
  };

  const LastName = (event) => {
    setLastName(event);
    checkFormValid();
  };

  const checkPhoneExists = (event) => {
    console.log(event);
    checkFormValid();
    setPhone(event);

    if (String(event).length > 9) {
      
      setLoading(true);

      //console.log("checkPhoneExists");

      const myHeaders = new Headers();
      myHeaders.append("Accept", "text/plain");

      const requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
        headers: {
          "X-API-KEY": apiKey, // Replace with your actual API key
        },
      };

      var url = apiHostUrl + "/user/GetUserByPhoneNo/" + event;

      fetch(url, requestOptions)
        .then((response) => response.text())

        .then((result) => {
          let foundData = JSON.parse(result);

          setLoading(false);

          if (String(foundData._id).length > 5) {

            if (Number(foundData.userRole == 0)) {

              //console.log("found");
              
              setUserExists(true);
              setValidEmail("red");

            }
          }
        })
        .catch((error) => console.error(error));
    } else {

      setUserExists(false);
      checkFormValid();

    }
  };

  return (
    <>
      {/* todo */}
      {!formSubmitted ? (
        <div className="container_2">
          {/* <h2>Loyalty Login</h2> */}
          <div>
            <div className="form-group">
              <label htmlFor="Restaurant">Restaurant:</label>
              <select
                value={restaurant}
                //onChange={handleRestaurantChange}
                id="restaurant"
              >
                <option value="0">Select your restaurant...</option>
                <option value="78B4BB90-A67B-40D6-AC6F-290062A9239A">
                Ballygowan Road
                </option>
                <option value="FA72CA82-61C3-4DD0-B78C-BCD4C75B923A">
                Ballyhackamore
                </option>
                <option value="A700CB5F-3CF0-4A00-B749-2F77036C5249">
                Carryduff
                </option>
                <option value="0C37ED2D-7588-44F3-97EE-EA012B14FA78">
                Dundonald
                </option>
                <option value="9C161FE9-D99E-4E36-BE6D-9A867528854E">
                Holywood
                </option>
                <option value="6969A641-769C-4D87-AD01-8CC586F5F997">
                Lisburn
                </option>
                <option value="7671ED19-9BAF-4795-AB37-9DD8CCFACA1B">
                Newtownards
                </option>
              </select>
            </div>

            {/* <label htmlFor="my-select">Hospitality Professional: </label>
            <select
              id="my-select"
              value={selectedStaff}
              onChange={handleStaffChange}
            >
              <option value="">Select your professional</option>
              <option value="0">Don't know</option>
              {staffOptions.map((staff) => (
                <option key={staff.id} value={staff.id}>
                  {staff.name}
                </option>
              ))}
            </select> */}

            <div className="form-group">
              <label htmlFor="firstName">First Name:</label>
              <div style={{ display: "flex", alignItems: "center" }}>
              <span style={{ marginRight: "3px", color: validFirstName, fontWeight: "bold" }}>*</span>
              <input
                type="text"
                id="siamfirstName"
                value={firstName}
                onChange={(e) => FirstName(e.target.value)}
                placeholder="First name"
                required
              />
              </div>
            </div>
            <div className="form-group">
              <label htmlFor="lastName">Last Name:</label>
              <div style={{ display: "flex", alignItems: "center" }}>
              <span style={{ marginRight: "3px", color: validLastName, fontWeight: "bold" }}>*</span>
              <input
                type="text"
                id="siamlastName"
                value={lastName}
                onChange={(e) => LastName(e.target.value)}
                placeholder="Last name"
                required
              />
              </div>
            </div>
            <div className="form-group">
              <label htmlFor="email">Email:</label>

              <div style={{ display: "flex", alignItems: "center" }}>
              <span style={{ marginRight: "3px", color: validEmail, fontWeight: "bold" }}>*</span>
              <input
                type="email"
                id="siamemail"
                value={email}
                onChange={handleChangeEmail}
                style={{ borderColor: isValid ? "" : "red" }}
                placeholder="Email address"
                required
              />
              </div>
              {!isValid && (
                <p style={{ color: "red" }}>
                  Please enter a valid email address
                </p>
              )}
              
            </div>
            <div className="form-group">
              <label htmlFor="phone">Phone:</label>
              
              <div style={{ display: "flex", alignItems: "center" }}>
              <span style={{ marginRight: "3px", color: validPhone, fontWeight: "bold" }}>*</span>
            <input
              type="tel"
              id="siamphone"
              value={phone}
              onChange={(e) => checkPhoneExists(e.target.value)}
              placeholder="Phone number"
              required
            />
          </div>
            </div>

            {userExists && (
              <div>
                <label className="warning">Account already exists</label>
              </div>
            )}

            <div className="form-group">
              <label htmlFor="birthMonth">Birth Month:</label>
              <select
                value={birthMonth}
                onChange={handleBirthMonthChange}
                id="birthMonth"
              >
                <option value="0">Select your birth month...</option>
                <option value="1">January</option>
                <option value="2">February</option>
                <option value="3">March</option>
                <option value="4">April</option>
                <option value="5">May</option>
                <option value="6">June</option>
                <option value="7">July</option>
                <option value="8">August</option>
                <option value="9">September</option>
                <option value="10">October</option>
                <option value="11">November</option>
                <option value="12">December</option>
              </select>
            </div>

            <div className="form-group">
              <label htmlFor="lastPassword">Password:</label>

              <div style={{ position: "relative", display: "inline-block" }}>
                <input
                  type={showPassword ? "text" : "password"}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder="Enter your password"
                  className="password-input"
                  style={{ paddingRight: "30px" }} // Make space for the eye icon
                />

                <span
                  onClick={togglePasswordVisibility}
                  style={{
                    position: "absolute",
                    right: "10px",
                    top: "50%",
                    transform: "translateY(-50%)",
                    cursor: "pointer",
                  }}
                >
                  {showPassword ? <FaEyeSlash /> : <FaEye />}{" "}

                </span>
              </div>

            </div>
            
            <button
              className="form-control"
              type="submit"
              disabled={!formValid}


              onClick={handleFormSubmit}
            >
              {submitButton}

            </button>
           
          </div>
        </div>
      ) : (
        <div>
          <div className="text-center">
            <h1>Welcome to John Dory'second Loyalty</h1>

            <Login phone={phone} password={password} />

            <p></p>
          </div>
        </div>
      )}

      {loading ? (
        <div className="spinner">
          {" "}
          <img src="../Infinity_Green.svg" />
        </div>
      ) : (
        ""
      )}

      <br />

      {showConsent ? (
  <div className="bannerStyle">
    <p>This website uses cookies to ensure you get the best experience. By continuing, you accept our cookie policy.</p>
    <p style={{ fontSize: '1.7em' }}> <a onClick={handleAccept} class="styled-link">Accept</a></p>
    <p><a href="https://siamthai.ie/terms-and-conditions/" target="_blank" rel="noopener noreferrer" class="styled-link">
  Terms and Conditions
</a> | <a href="https://siamthai.ie/privacy-policy/" target="_blank" rel="noopener noreferrer" class="styled-link">
Privacy Policy
</a></p>
   
  </div>
) : null}
{/* </form> */}
    </>
  );
};

export default Register;
