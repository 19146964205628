

export const getRandomIntInclusive = (min, max) =>
    {

        min = Math.ceil(min); // Inclusive lower bound
        max = Math.floor(max); // Inclusive upper bound
        return Math.floor(Math.random() * (max - min + 1) + min);

   }
   
   export const SetPasswordOnServer = (userId,password, apiUrl,apiKey) => {


     console.log('======================================================')   

     console.log(userId)
     console.log(password)
     console.log('======================================================')   


    // the purpose of this is to check two passwords
    // if there is a match then the password will aotomatically be updated 
    // and the user reditected to the login screen
    // console.log("check password2");
    // console.log(event);
    // console.log(password1);

   
      //console.log("its a match");

      //console.log(userId);

      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("X-API-KEY", apiKey)

      const raw = JSON.stringify({
        id: userId,
        password: password,
      });

      const requestOptions = {
        method: "PUT",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };
      var url = apiUrl + "/user/SetPassword"

        console.log(url)

      fetch(
        url,
        requestOptions
      )
        .then((response) => response.text())
        .then((result) => {
          console.log(result)
        })
        .catch((error) => console.error(error));
  };