import React ,{  useState, useEffect }  from 'react'
import { Link } from 'react-router-dom';
//import './HomeInfo.css'


const HomeInfo = () => {


  const [showConsent, setShowConsent] = useState(false);
  
  useEffect(() => {
   
    const consent = localStorage.getItem('gdprConsent');

    if (!consent) {

      setShowConsent(true);  // Show consent banner if consent is not yet given
    }

  }, []); // Empty dependency array means this effect runs once when the component mounts

  const handleAccept = () => {
    localStorage.setItem('gdprConsent', 'accepted');  // Store consent
    setShowConsent(false);  // Hide banner
  };

  const bannerStyle = {
    position: 'fixed',
    bottom: 0,
    width: '90%',
    backgroundColor: '#000',
    color: '#fff',
    padding: '10px',
    textAlign: 'center',
    zIndex: 1000,
  };
  
  const buttonStyle = {
    backgroundColor: '#000',
    color: 'white',
    border: 'none',
    padding: '2px 4px',
    cursor: 'pointer',
    marginLeft: '5px',
  };

  return (

    <>
    
    <h3>Home</h3>

   <Link to="/register">
          <button className='secondary-button' style={{ margin: '3px' }}>Register Now</button>
    </Link>
    <br/>
    <Link to="/login">
          <button className='secondary-button' style={{ margin: '3px' }}>Check My Points</button>
    </Link>
   
  {showConsent ? (
 
  <div className="bannerStyle">
    <hr/>
    <h6>This website uses cookies to ensure you get the best experience. By continuing, you accept our cookie policy.</h6>
    <p style={{ fontSize: '1.7em' }}> <a href='' onClick={handleAccept} >Accept</a></p>
    <p><a href="https://www.johndorys.co.uk/terms" target="_blank" rel="noopener noreferrer" >
  Terms and Conditions
</a> | <a href="https://www.johndorys.co.uk/privacy" target="_blank" rel="noopener noreferrer" >
Privacy Policy
</a>
| <a href="https://www.johndorys.co.uk/cookiepolicy" target="_blank" rel="noopener noreferrer" >
Cookie Policy
</a></p>
   
  </div>
) : null}
    </>
    
  )
}

export default HomeInfo