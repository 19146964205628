// Footer.js
// import React from 'react';

// const Footer = () => {
//   return (
//     <footer>

//       <hr/>
//       <div> <a href="https://www.johndorys.co.uk/">John Dorys Website</a></div>
//       <div>   <a href="https://www.johndorys.co.uk/order">Order Online </a></div>
     
    
//     </footer>
//   );
// }

// export default Footer;

import React from 'react';

const Footer = () => {
  return (
    <footer style={styles.footer}>
    <hr style={styles.hr} />
    <div style={styles.linkWrapper}>
      <a href="https://www.johndorys.co.uk/" style={styles.link}>
        John Dory's
      </a>
      <a href="https://www.johndorys.co.uk/order" style={styles.link}>
        Order Online
      </a>
    </div>
  </footer>
  );
};

// const styles = {
//   footer: {
//     textAlign: 'center',
//     padding: '20px',
//     //backgroundColor: '#f8f8f8',
//   },
//   hr: {
//     margin: '0 auto',
//     width: '80%',
//     border: '1px solid #ddd',
//   },
//   linkContainer: {
//     margin: '10px 0',
//   },
//   link: {
//     textDecoration: 'none',
//     color: '#333',
//     fontWeight: 'bold',
//   },
// };

const styles = {
  footer: {
    padding: "10px 0",
    textAlign: "center",
  },
  hr: {
    margin: "10px 0",
  },
  linkWrapper: {
    display: "flex",
    justifyContent: "center",
    gap: "20px", // Space between links
  },
  link: {
    color: "#000", // Adjust link color as needed
    textDecoration: "none",
    fontSize: "16px",
  },
};

export default Footer;
