import React from 'react';
import Barcode from 'react-barcode';

const PhoneBarcode = ({ phoneNumber }) => {
  return (
    <div>
      <h3>Phone Number Barcode</h3>
      <Barcode value={phoneNumber} />
    </div>
  );
};

export default PhoneBarcode;