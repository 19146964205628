import React from "react";
import { useState, useEffect, useContext } from "react";
import { Navigate } from "react-router-dom";
import { getRandomIntInclusive } from "./Utils";
import { GlobalStateContext } from "../pages/GlobalStateContext";

const Transactions = ({ userId }) => {
  const { apiHostUrl, setApiHostUrl } = useContext(GlobalStateContext);
  const { apiKey, setApiKey } = useContext(GlobalStateContext);
  const [data, setData] = useState([]);
  const [loadingT, setLoadingT] = useState(true);

  useEffect(() => {
    let randomT = getRandomIntInclusive(1000, 3000);
    setTimeout(() => {
      const myHeaders = new Headers();
      myHeaders.append("Accept", "text/plain");
      myHeaders.append("X-API-KEY", apiKey); // Replace with your actual API key

      const requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      var url = apiHostUrl + "/user/ListTransactions/" + userId + "";

      fetch(url, requestOptions)
        .then((response) => response.text())
        .then((result) => {
          //console.log(result);

          setLoadingT(false);

          setData(JSON.parse(result));
        })
        .catch((error) => console.error(error));
    }, randomT);
  }, []);

  return (
    <>
      {loadingT ? <img src="../Small_spinner.svg" /> : ""}
      <h3>Recent Transactions</h3>

      <div
        style={{ height: "250px", overflowY: "auto", border: "0px solid #ccc" }}
      >
        <table className="table ">
          <tr>
            <th style={{ borderBottom: "1px solid #ddd", padding: "8px" }}>
              Date
            </th>
            <th style={{ borderBottom: "1px solid #ddd", padding: "8px" }}>
              Amount
            </th>
            <th style={{ borderBottom: "1px solid #ddd", padding: "8px" }}>
              Points
            </th>
            <th style={{ borderBottom: "1px solid #ddd", padding: "8px" }}>
              Before
            </th>
            <th style={{ borderBottom: "1px solid #ddd", padding: "8px" }}>
              After
            </th>
          </tr>

          {data.map((item) => {
            const formattedDate = new Date(item.date).toLocaleDateString(
              "en-GB"
            );
            return (
              <tr
                key={item.id}
                style={{ color: item.points < 0 ? "red" : "black" }}
              >
                <td>{item.date}</td>
                <td>{item.amount}</td>
                <td>{item.points}</td>
                <td>{item.before}</td>
                <td>{item.after}</td>
              </tr>
            );
          })}
        </table>
      </div>
    </>
  );
};

export default Transactions;
